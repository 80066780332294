<template>
  <div class="car-card bg-color bg-white">
    <Navbar title="车辆详情">
      <template #right v-if="!isDriver">
        <span class="text_primary px-10" @click="handleRemoveFromFleet">移出车队</span>
      </template>
    </Navbar>
    <div class="d-flex flex-column all-wrap pt-36 px-24 box-border" v-if="carDetail">
      <div class="flex justify-between items-center">
        <div class="text-muted text-16">
          车辆认证状态：<span class="font-500 text-16" :class="'text' + carDetail.auditStatus">{{ carStates }}</span>
        </div>
        <div class="d-flex items-center">
          <div class="text-14  operate-btn mr-12" @click="handleRemoveCar" v-if="isDriver">
            删除
          </div>
          <div class="text-14  operate-btn " @click="handleJumpEdit(carDetail.auditStatus)">
            <!-- <Icon name="icon_wode_shoujihao-2"></Icon> -->
            {{ carDetail.auditStatus === 2 ? '修改' : '重新认证' }}
          </div>
        </div>
      </div>

      <div class="px-12 py-16 flex rounded-4 mt-18" style="background: #FFE7E8;" v-if="carDetail.auditStatus === 3">
        <Icon name="icon_common_toast_warning_yellow"></Icon>
        <div class="pl-8 text-14 text-danger">
          {{ carDetail.refuseReason.map(item => item.remarks).join('、') }}
        </div>
      </div>

      <div class="car-detail-wrap bg-white box-border" :class="{ errorPadding: carDetail.auditStatus === 3 }">
        <div class="mt-32">
          <div class="text-18 text-main font-500 mb-16">车辆信息</div>
          <div class="flex justify-between items-center flex-wrap">
            <div class="car-detail">
              <tos-uploader
                :value="carDetail.drivingLicenseMainName"
                readonly
                class="image"
                bgType="xingshizheng-zhu"
              />
              <div class="text-center text-muted text-14 mt-6">行驶证主副页(主车)</div>
            </div>

            <div class="car-detail">
              <tos-uploader
                :value="carDetail.drivingLicenseSideName"
                readonly
                class="image"
                bgType="xingshizheng-zhu"
              />

              <div class="text-center text-muted text-14 mt-6">行驶证其他页(主车)</div>
            </div>
            <div class="car-detail pt-12">
              <tos-uploader
                :value="carDetail.transportLicenseMainName"
                readonly
                class="image"
                bgType="daoluyunshuzheng-zhu"
              />
              <div class="text-center text-muted text-14 mt-6">道路运输证(主车)</div>
            </div>
            <div class="car-detail pt-12">
              <tos-uploader
                :value="carDetail.transportLicenseSideName"
                readonly
                class="image"
                bgType="daoluyunshuzheng-zhu"
              />
              <div class="text-center text-muted text-14 mt-6">道路运输证其他页(主车)</div>
            </div>

            <div class="car-detail pt-12">
              <tos-uploader
                :value="carDetail.trailerLicenseMainName"
                readonly
                class="image"
                bgType="xingshizheng-zhu"
              />

              <div class="text-center text-muted text-14 mt-6">行驶证主副页(挂车)</div>
            </div>
            <div class="car-detail pt-12">
              <tos-uploader
                :value="carDetail.trailerLicenseSideName"
                readonly
                class="image"
                bgType="xingshizheng-zhu"
              />

              <div class="text-center text-muted text-14 mt-6">行驶证其他页(挂车)</div>
            </div>
            <div class="car-detail pt-12">
              <tos-uploader :value="carDetail.carWithOwnerFilename" readonly class="image" bgType="rencheheying" />
              <div class="text-center text-muted text-14 mt-6">人车合影照片</div>
            </div>
          </div>
        </div>
        <div class="mt-36">
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车牌号</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'carLicensePlate') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车辆所有人</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'owner') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车牌颜色</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'plateColorName') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车辆类型</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'truckTypeName') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">使用性质</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'useNature') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车辆识别代号</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'identifyCode') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">注册日期</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'issueDate') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">发证日期</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'registerDate') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">能源类型</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'energyTypeName') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">{{ loadWeightLabel }}kg</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'loadWeight') / 1000 }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">总质量kg</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'totalWeight') / 1000 }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">整备质量kg</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'curbWeight') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">发证机关</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'issueOrganization') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">检验有效期至</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'inspectionEndDate') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">档案编号</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'fileNumber') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">外廓长(mm)</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'outlineDimensionLength') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">外廓宽(mm)</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'outlineDimensionWidth') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">外廓高(mm)</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'outlineDimensionHeight') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">挂车牌照号</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'trailerPlateNumber') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">挂车类型</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'trailerType') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">挂车核定载质量kg</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'trailerLoadWeight') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">载重长度m</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'loadLength') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">车辆轴数</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'axles') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">道路运输证</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'roadTransportNumber') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">道路运输经营许可证</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'roadTransportPermitNumber') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">业户名称</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'businessName') }}</div>
          </div>
          <div class="flex justify-between items-start mb-24">
            <div class="text-muted text-16 flex-shrink-0">运输证有效期至</div>
            <div class="text_style text-main text-16">{{ $$(carDetail, 'validEndDate') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick, computed } from '@vue/composition-api';
import { apiGetCarDetail, apiWithdrawTruck } from '@api/mine';
import { useRouterQuery } from '@hook/useRouterQuery';
import { apiGetImgUrl } from '@api/common';
import { plateEnumMap, carsTypeEnumMap } from '../../constants/index';
import { carsStateEnum, checkTrailerBytype } from '../constants.js';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useRouter } from '@/utils/compsitionHack';
import { Uploader, Dialog, Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';
import { useTruckVerifiedStore } from '@/store/truckVerified';
import { removeTruckFromFleetApi } from '@/api/carrier-truck';
import { deleteTruckByDriverApi } from '@/api/truck';
import { useUserStore } from '@/store/user';
import { useCarrierStore } from '@/store/carrier';

import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Vehicle from '@/services/AuthenticationMode/Vehicle';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';
import TosUploader from '@/components/uploader/uploader.vue';
import { gram2weight, cent2yuan } from '@/utils/unit';
const authModeFactory = new AuthModeFactory(new Vehicle());

//1-待审核 2-已审核 3-审核不通过
export default defineComponent({
  name: 'CarDetail',
  components: {
    Uploader,
    TosUploader
  },
  setup() {
    const query = useRouterQuery();
    const router = useRouter();
    let carDetail = ref();
    let carStates = ref();
    const truckRef = ref({});

    // 1.3.4 修改车辆信息
    const truckVerifiedStore = useTruckVerifiedStore();
    truckVerifiedStore.updateAuthModeFields(authModeFactory.build(ModeEnum.INTEGRITY));
    const userStore = useUserStore();
    const carrierStore = useCarrierStore();
    const isDriver = computed(() => userStore.isDriver);
    // =========
    const loadWeightLabel = computed(() => {
      const truckTypeCode = truckRef.value?.truckTypeCode;
      if (!truckTypeCode || !checkTrailerBytype(truckTypeCode)) return '核定载质量';
      return '准牵引总质量';
    });
    onMounted(async () => {
      try {
        const res = await apiGetCarDetail({
          truckId: query.id
        });
        if (res?.data) {
          // TODO: 测试图片地址

          const [
            drivingMainRes,
            transMainRes,
            drivingSideRes,
            transSideRes,
            carOwnerRef,
            trailerMainRes,
            trailerSideRes
          ] = await Promise.all([
            apiGetImgUrl({
              filename: res.data.drivingLicenseMainUrl || ''
            }),
            apiGetImgUrl({
              filename: res.data.transportLicenseMainUrl || ''
            }),
            apiGetImgUrl({
              filename: res.data.drivingLicenseSideUrl || ''
            }),
            apiGetImgUrl({
              filename: res.data.transportLicenseSideUrl || ''
            }),
            apiGetImgUrl({
              filename: res.data.carWithOwnerUrl
            }),
            apiGetImgUrl({
              filename: res.data.trailerDrivingLicenseMainUrl || ''
            }),
            apiGetImgUrl({
              filename: res.data.trailerDrivingLicenseSideUrl || ''
            })
          ]);
          carDetail.value = {
            ...res.data,
            refuseReason: res.data.refuseReason || [],
            drivingLicenseMainName: drivingMainRes.data
              ? [
                  {
                    url: drivingMainRes.data
                  }
                ]
              : [],
            transportLicenseMainName: transMainRes.data
              ? [
                  {
                    url: transMainRes.data
                  }
                ]
              : [],
            drivingLicenseSideName: drivingSideRes.data
              ? [
                  {
                    url: drivingSideRes.data
                  }
                ]
              : [],
            transportLicenseSideName: transSideRes.data
              ? [
                  {
                    url: transSideRes.data
                  }
                ]
              : [],
            carWithOwnerFilename: carOwnerRef.data ? [{ url: carOwnerRef.data }] : [],
            trailerLicenseMainName: trailerMainRes.data ? [{ url: trailerMainRes.data }] : [],
            trailerLicenseSideName: trailerSideRes.data ? [{ url: trailerSideRes.data }] : [],
            plateColorName: plateEnumMap[res.data.plateColor],
            energyTypeName: carsTypeEnumMap[res.data.energyType],
            registerDate: formatToDateTime(res.data.registerDate, 'yyyy-MM-dd'),
            issueDate: formatToDateTime(res.data.issueDate, 'yyyy-MM-dd'),
            inspectionEndDate: res.data.inspectionEndDate
              ? formatToDateTime(res.data.inspectionEndDate, 'yyyy-MM')
              : '--',
            curbWeight: res.data.curbWeight ? res.data.curbWeight / 1000 : '--',
            validEndDate: res.data.validEndDate ? formatToDateTime(res.data.validEndDate, 'yyyy-MM-dd') : '--',
            loadLength: res.data.loadLength ? cent2yuan(res.data.loadLength) : '--',
            trailerLoadWeight: res.data.trailerLoadWeight ? gram2weight(res.data.trailerLoadWeight, 2) : '--',
            axles: res.data.axles ? res.data.axles + '轴' : '--'
          };
          carsStateEnum.forEach(item => {
            if (item.key == carDetail.value.auditStatus) {
              carStates.value = item.value;
            }
          });
          truckRef.value = res.data;
          truckRef.value.energyTypeName = carsTypeEnumMap[res.data.energyType];
        }
      } catch (err) {
        Toast.fail(err);
      }
    });

    const handleJumpEdit = async type => {
      router.push({
        path: PageEnum.BASE_CAR_ADD,
        // path: '/mine/car/trailer/edit',
        query: {
          truckId: query.id,
          isEdit: true // 如果是true调用更新接口false调用添加车辆接口
        }
      });
      return;
    };

    const handleRemoveFromFleet = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否确定移除该车辆？',
        confirmButtonText: '移除'
      }).then(async () => {
        const { carrierId } = userStore.userInfo;
        const { truckId } = truckRef.value;
        try {
          await removeTruckFromFleetApi({ carrierId, truckId });
          await carrierStore.getFleetTrucksAction();
          nextTick(() => {
            router.back();
          });
        } catch (error) {
          console.log('err is ->', error);
        }
      });
    };
    const deleteLoading = ref(false);
    const handleRemoveCar = async () => {
      if (!isDriver.value || deleteLoading.value) return;
      try {
        await Dialog.confirm({
          title: '提示',
          message: '是否确认删除该车辆？',
          confirmButtonText: '删除'
        });
        deleteLoading.value = true;
        Toast.loading({ message: '删除中...' });
        const { driverId } = userStore.userInfo;
        const { truckId } = truckRef.value;
        await deleteTruckByDriverApi({ driverId, truckId });
        await carrierStore.getFleetTrucksAction();
        nextTick(() => {
          router.back();
        });
        Toast.success('车辆已删除');
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          Toast.clear();
          deleteLoading.value = false;
        }, 1500);
      }
    };

    return {
      carDetail,
      carStates,
      handleJumpEdit,
      handleRemoveFromFleet,
      isDriver,
      handleRemoveCar,
      loadWeightLabel
    };
  }
});
</script>
<style lang="less">
.all-wrap {
  // height: calc(~'100vh - .44rem');
  .text_style {
    max-width: 2.4rem;
  }
}
.car-detail-wrap {
  // flex: 1;
  // height: 100%;
  // overflow: scroll;
  .car-detail {
    background: white;
    .image {
      width: 1.56rem;
      height: 1rem;
    }

    .d-inline {
      display: inline;
    }
  }
}
.error-wrap {
  background-image: linear-gradient(to bottom, transparent 50%, white 51%);
  width: 100vw;
  min-height: 1.67rem;
  .error-message {
    color: white;
    background: rgba(255, 96, 97, 0.8);
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px rgba(200, 207, 216, 0.5);
    .button-reset {
      color: rgba(255, 255, 255, 0.8);
      background: #ffffff;
      width: 100%;
      border-radius: 4px;
      height: 0.38rem;
      color: rgba(255, 127, 129, 1);
      text-align: center;
    }
  }
}
.warning-wrap {
  background-image: linear-gradient(to bottom, transparent 50%, white 51%);
  width: 100vw;
  min-height: 1.67rem;
  .warning-message {
    border-radius: 4px;
    color: white;
    box-shadow: 0px 4px 10px 0px rgba(200, 207, 216, 0.5);
    background: rgba(252, 148, 18, 0.8);
    .button-warning {
      color: rgba(252, 148, 18, 1);
      background: #ffffff;
      width: 100%;
      border-radius: 4px;
      height: 0.38rem;
      text-align: center;
    }
  }
  .default-class {
    background: #f8f8f8;
  }
  .error_class {
    border-bottom: 1px solid #e2e2e2;
  }
  .error_class_box {
    background: #fdeaeb;
  }
}
.text1 {
  color: #333 !important;
}
.text2 {
  color: #00ac95 !important;
}
.text3 {
  color: #e72832 !important;
}
.operate-btn {
  text-align: center;
  height: 24px;
  line-height: 23px;
  border: 0.5px solid;
  color: #333;
  box-sizing: border-box;
  min-width: 0.54rem;
  padding: 0 6px;
  border-radius: 0.12rem;
}
</style>
