<template>
  <div class="drivers-step1-content">
    <!-- 按钮栏 -->
    <div class="bg-white flex flex-row" v-if="authModeFields.drivingLicenseMainUrl">
      <div class="pl-24 pr-6 flex-1">
        <div class="d-flex justify-space-between">
          <label class="require ml-10 text-14 text-main font-500"
            >行驶证主副页<span class="text-danger">(主车)</span></label
          >
          <div class="font-6" v-if="truckRef.reasonKeys.includes('drivingLicenseMainUrl')">
            不正确
          </div>
        </div>
        <div class="truck-card-wrap py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseMainFileList"
            @afterUploaded="afterUploadedTruckCard"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
      <div class="pl-6 pr-24 flex-1">
        <div class="d-flex justify-space-between">
          <label class="ml-5 text-14 text-main font-500">行驶证其他页<span class="text-danger">(主车)</span></label>
        </div>
        <div class="truck-card-wrap  py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseSideFileList"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
    </div>
    <div class="relative pt-8 text-center text-note text-12">上传行驶证照片，可快速识别车牌号</div>
    <div class="form noborder px-24" v-if="authModeFields.carLicensePlate">
      <div class="form-title flex-nowrap d-flex mb-8">
        <div class="important" :class="{ titleError: truckRef.reasonKeys.includes('carLicensePlate') }">车牌号</div>
        <div class="font-12" v-if="truckRef.reasonKeys.includes('carLicensePlate')">
          不正确
        </div>
      </div>
      <div class="d-flex justify-space-between" @click="isAmendTruckAuthInfo ? null : (popVisible = true)">
        <span
          class="carnumber-box"
          :class="isAmendTruckAuthInfo ? 'text-muted' : ''"
          v-for="(item, index) in carNumberPro.length === 0 ? ['', '', '', '', '', '', ''] : carNumberPro"
          :key="index"
        >
          {{ item }}
        </span>
      </div>
    </div>
    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          :readonly="['select', 'datePicker'].includes(item.type)"
          v-if="!item.hidden && item.type !== 'custom'"
          @click="['select', 'datePicker'].includes(item.type) ? (FormObj[key].visible = true) : ''"
          :required="item.rules[0].required || item.showRequiredIcon"
          :value="truckRef[key]"
          @input="handleInput($event, key)"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          :type="item.type"
          :autosize="item.autosize"
          rows="1"
          :key="key"
          @blur="onBlur(key)"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
              <div class="text-12 text-warning mt-8" v-if="key === 'totalWeight' && trailerPlateNumberRequired">
                准牵引总质量+整备质量
              </div>
            </div>
          </template>
          <template v-if="item.extra" #extra>
            <div class="ml-8">{{ item.extra }}</div>
          </template>
        </van-field>
        <van-field
          readonly
          v-if="!item.hidden && key == 'trailerType'"
          @click="trailerTypeShowRef = true"
          :required="item.rules[0].required"
          :value="truckRef[key] || '请选择'"
          input-align="right"
          :name="item.name"
          placeholder="请选择"
          rows="1"
          :key="key"
          :rules="item.rules"
          is-link
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes('trailerTypeCode') }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes('trailerTypeCode')">
                不正确
              </div>
            </div>
          </template>
        </van-field>
        <van-field
          v-if="key === 'trailerLoadWeight' && !item.hidden"
          :required="item.rules[0].required"
          v-model="truckRef[key]"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          type="digit"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template v-if="item.extra" #extra>
            <div class="ml-8">{{ item.extra }}</div>
          </template>
        </van-field>
        <van-field
          v-if="key === 'loadLength' && !item.hidden"
          :required="trailerConfig"
          v-model="truckRef[key]"
          @blur="onLoadLengthChange"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          type="number"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template #extra>
            <div class="ml-8 text-primary" @click="lengthPopShow = true">推荐</div>
          </template>
        </van-field>
      </template>
    </van-form>

    <div>
      <div class="truck-btn-box">
        <Button size="large" :disabled="next" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
    <Popup v-model="popVisible" position="bottom" round>
      <KeyBoard
        :defaultValue="carNumberPro"
        :defaultType="'carPlate'"
        @getPlateLicense="getPlateLicense"
        @hide="popVisible = false"
      />
    </Popup>

    <Popup v-model="FormObj.plateColor.visible" round position="bottom">
      <CheckPorps
        title="车牌颜色选择"
        v-model="truckRef.plateColor"
        :columns="FormObj.plateColor.options"
        @confirm="e => handlePopConfirm(e, 'plateColor')"
        @cancel="FormObj.plateColor.visible = false"
      />
    </Popup>
    <Popup v-model="FormObj.truckTypeName.visible" :style="{ height: '100%' }" position="left">
      <TruckTypePicker
        title="请选择车辆类型"
        v-model="truckRef.truckTypeName"
        :columns="FormObj.truckTypeName.options"
        @confirm="e => onTruckTypeChange(e, 'truckType')"
        @cancel="FormObj.truckTypeName.visible = false"
      />
    </Popup>

    <!-- 挂车牌照 -->

    <ConfirmModal :carInfo="truckRef" ref="confirm" @handleComfirm="onConfirm" />

    <Popup v-model="trailerTypeShowRef" :style="{ height: '100%' }" position="left">
      <TruckTypePicker
        title="请选择车辆类型"
        v-model="truckRef.trailerType"
        :columns="trailerTypesOptions"
        @confirm="onTrailerTypeChange"
        @cancel="trailerTypeShowRef = false"
      />
    </Popup>
    <van-popup v-model="lengthPopShow" position="bottom">
      <van-picker
        title="推荐长度"
        show-toolbar
        :columns="lengthOptions"
        value-key="text"
        @confirm="onLengthConfirm"
        @cancel="lengthPopShow = false"
      />
    </van-popup>
  </div>
</template>
<script>
import TosUploader from '@/components/uploader/uploader.vue';
import { defineComponent, ref, computed, reactive, onMounted, nextTick, watch } from '@vue/composition-api';
import KeyBoard from '../components/carBoard';
import { Button, Popup, Toast, Dialog, Picker } from 'vant';
import { useRouter } from '@/utils/compsitionHack';
import { useTruckVerifiedStore } from '@/store/truckVerified.js';
import { apiOcrVehicleLicense } from '@/api/ocr';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStore } from '@/store/user';
import { apiGetLicensesByPlate } from '@/api/common';
import { getStep1FormData, checkTrailerBytype } from '../constants';
import { buildFormByAuthModeFields } from '@/utils/authMode';
import CheckPorps from './checkCarProps';
import TruckTypePicker from './TruckTypePicker';
import { apiGetCarType } from '@api/mine';
import ConfirmModal from './submit-popup.vue';
import { getOrgnazitionByPlate } from './utils';
import { formatWeightOcr } from '@/utils/ocr';
import BigNumber from 'bignumber.js';
import { carsTypeEnumMap } from '@/views/mine/constants';
import { useColorStrategy } from '@/hook/useColorStrategy';
import { plateEnumMap } from '../../constants';
export default defineComponent({
  name: 'carAddStep1',
  components: {
    TosUploader,
    Button,
    Popup,
    KeyBoard,
    Toast,
    CheckPorps,
    TruckTypePicker,
    ConfirmModal,
    'van-picker': Picker
  },
  setup(_, ctx) {
    const userVerifiedStore = useTruckVerifiedStore();
    const { runStrategy } = useColorStrategy();
    // 认证模式
    const authModeFields = userVerifiedStore.authModeFields;
    const popVisible = ref(false);
    const disabled = ref(false); // 全局禁用
    const router = useRouter();
    const next = ref(false);
    const userStore = useUserStore();
    const isDriver = computed(() => userStore.isDriver);
    const carNumberPro = computed(() => userVerifiedStore.verifiedInfo.carLicensePlate);
    const truckRef = computed(() => userVerifiedStore.verifiedInfo);
    const truckLicenseMainFileList = ref([]);
    const truckLicenseSideFileList = ref([]);

    const isAmendTruckAuthInfo = computed(() => !!userVerifiedStore?.verifiedInfo?.truckId);
    // const isImagePass = computed(() => userVerifiedStore.imagePass);
    const trailerPlateNumberRequired = computed(() => {
      const truckTypeCode = userVerifiedStore.verifiedInfo.truckTypeCode;
      return !!truckTypeCode && checkTrailerBytype(truckTypeCode);
    });
    const FormData = getStep1FormData(truckRef, !!userStore.showReviewKeys, userVerifiedStore);
    buildFormByAuthModeFields(FormData, userVerifiedStore.authModeFields);
    const FormObj = reactive(FormData);
    const trailerConfig = computed(() => {
      return userVerifiedStore.trailerConfig;
    });
    onMounted(() => {
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl) {
        truckLicenseMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl) {
        truckLicenseSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      const isRequired = trailerPlateNumberRequired.value;
      FormObj.loadLength.hidden = isRequired;
      getCarTypes();
    });

    const getCarTypes = async () => {
      // 拉取车辆类型
      const res = await apiGetCarType();
      FormObj.truckTypeName.options = res.data;
      res.data
        .map(item => item.secondCarTypes)
        .flat(2)
        .forEach(item => {
          if (item.name === truckRef.value.truckTypeName) {
            userVerifiedStore.updateInfo({
              truckTypeName: item.name,
              truckTypeCode: item.code
            });
          }
        });
      if (
        // 没有识别出来车辆类型
        !res.data
          .map(item => item.secondCarTypes)
          .flat(2)
          .map(i => i.name)
          .includes(truckRef.value.truckTypeName)
      ) {
        userVerifiedStore.updateInfo({
          truckTypeName: '',
          truckTypeCode: ''
        });
      }
    };

    const getCarTypeCode = name => {
      const tree = FormObj.truckTypeName.options || [];
      const flattedTypeList = tree.reduce((res, cur) => {
        let children = cur.secondCarTypes || [];
        if (children.length) {
          res = [...res, ...children];
        }
        return res;
      }, []);
      const data = flattedTypeList.find(it => it.name === name);
      if (data) {
        return {
          truckTypeCode: data.code,
          truckTypeName: data.name
        };
      }
      return null;
    };

    const getPlateLicense = async number => {
      userVerifiedStore.updateInfo({
        carLicensePlate: number
      });
      afterPlateChange(number);
    };

    const afterPlateChange = async carLicensePlate => {
      const carValidErr = vaildateCarNumber(carNumberPro.value);
      if (carValidErr) {
        Toast.fail(carValidErr);
        return;
      }
      const isExist = await userVerifiedStore.checkTruckExist(carLicensePlate);
      if (isExist === 1) {
        const msg = `【${carLicensePlate}】 已存在，是否添加车辆`;
        Dialog.confirm({
          message: msg
        }).then(
          async () => {
            const isSuccess = await userVerifiedStore.addTruckByExist(carLicensePlate);
            Toast.clear();
            if (isSuccess) {
              Toast.success('添加成功');
              userVerifiedStore.updateInfo({
                // 跳转之前做缓存数据的清空
                drivingLicenseMainUrl: '',
                carLicensePlate: ''
              });
              nextTick(() => {
                router.push(isDriver.value ? PageEnum.BASE_CAR_LIST : PageEnum.CARRIER_FLEET);
              });
            } else {
              userVerifiedStore.updateInfo({
                carLicensePlate: ''
              });
            }
          },
          () => {
            userVerifiedStore.updateInfo({
              carLicensePlate: ''
            });
          }
        );
      } else if (isExist === -1) {
        userVerifiedStore.updateInfo({
          carLicensePlate: ''
        });
      }
    };

    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      let flag = true;
      if (form) {
        try {
          flag = await form.validate(...params);
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      return flag;
    };

    const afterUploadedTruckCard = async file => {
      // userVerifiedStore.updateImagePass(false);
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      next.value = true;
      try {
        const res = await apiOcrVehicleLicense({ imageUrl: file.url });
        const { data } = res;
        const {
          carNumber,
          vehicleHeight,
          vehicleLength,
          vehicleWidth,
          vin,
          owner,
          issueDate,
          vehicleType,
          useCharacter,
          sealOrganize,
          fileNo,
          registerDate
        } = data;
        const { totalWeight, loadWeight, curbWeight } = formatWeightOcr(data);
        let changedData = {
          owner,
          registerDate: registerDate && strDateTime(registerDate) ? registerDate : '',
          issueDate: issueDate && strDateTime(issueDate) ? issueDate : '',
          outlineDimensionHeight: vehicleHeight,
          outlineDimensionLength: vehicleLength,
          outlineDimensionWidth: vehicleWidth,
          identifyCode: vin,
          useNature: useCharacter,
          totalWeight,
          loadWeight,
          issueOrganization: sealOrganize,
          fileNumber: fileNo,
          curbWeight
        };
        const energyTypeCode = data.matchEnergyType || data.energyTypeCode || null;
        if (energyTypeCode && carsTypeEnumMap[energyTypeCode]) {
          changedData.energyTypeCode = energyTypeCode;
          changedData.energyType = carsTypeEnumMap[energyTypeCode];
        }
        const typeObj = getCarTypeCode(vehicleType);
        if (typeObj) {
          changedData = { ...changedData, ...typeObj };
        }
        let goodData = Object.keys(changedData).reduce((res, key) => {
          if (changedData[key]) {
            res[key] = changedData[key];
          }
          return res;
        }, {});
        if (!isAmendTruckAuthInfo.value && carNumber) {
          getPlateLicense(carNumber);
        }
        userVerifiedStore.updateInfo(goodData);
        if (Object.keys(goodData).length > 0) {
          runStrategy(
            { ...userVerifiedStore.verifiedInfo, energyType: userVerifiedStore.verifiedInfo.energyTypeCode },
            color => {
              userVerifiedStore.updateInfo({
                plateColorCode: +color,
                plateColor: plateEnumMap[color]
              });
            }
          );
        }
      } catch (error) {
        // userVerifiedStore.updateImagePass(true);
        disabled.value = false;
        file.status = 'done';
      } finally {
        next.value = false;
      }
    };

    const strDateTime = str => {
      let r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
      if (r == null) return false;
      let d = new Date(r[1], r[3] - 1, r[4]);
      return d.getFullYear() == r[1] && d.getMonth() + 1 == r[3] && d.getDate() == r[4];
    };

    const dealValueBeforeNext = async () => {
      try {
        const res = await apiGetLicensesByPlate({
          licensePlate: carNumberPro.value.slice(0, 2),
          pageNum: 1,
          pageSize: 10
        });
        const { records } = res.data;
        if (!records || !records[0]) return;
        const {
          issueOrganization,
          totalWeight,
          useNature,
          roadTransportNumber,
          roadTransportPermitNumber
        } = userVerifiedStore.verifiedInfo;
        const { authMode } = userVerifiedStore.authModeFields;
        const obj = {};
        if (!issueOrganization && records?.[0]?.province) {
          const val = getOrgnazitionByPlate(records[0]);
          val && (obj.issueOrganization = val);
        }
        if (!useNature) {
          obj.useNature = totalWeight <= 4500 ? '非营运' : '货运';
        }
        const districtCode = records[0]?.districtCode;
        if (totalWeight <= 4500) {
          if (districtCode) {
            obj.roadTransportNumber = `${districtCode}000000`;
            obj.roadTransportPermitNumber = `${districtCode}000000`;
            userVerifiedStore.setRoadDisabled(true);
          } else {
            userVerifiedStore.setRoadDisabled(false);
          }
        } else {
          userVerifiedStore.setRoadDisabled(false);
          if (authMode < 1 && !roadTransportNumber) {
            obj.roadTransportNumber = `${districtCode}000001`;
          }
          if (authMode < 2 && !roadTransportPermitNumber) {
            obj.roadTransportPermitNumber = `${districtCode}000002`;
          }
        }
        userVerifiedStore.updateInfo(obj);
      } catch (e) {
        //
        userVerifiedStore.setRoadDisabled(false);
      }
    };
    const onConfirm = async () => {
      if (ctx.root.$route.query.isEdit !== 'true') {
        await dealValueBeforeNext();
      }
      userVerifiedStore.getAxlesByLength(userVerifiedStore.verifiedInfo.loadLength);
      const params = trailerPlateNumberRequired.value
        ? {}
        : {
            trailerPlateNumber: null,
            trailerType: null,
            trailerTypeCode: null,
            trailerLoadWeight: null,
            trailerDrivingLicenseMainUrl: '',
            trailerDrivingLicenseSideUrl: ''
          };
      userVerifiedStore.updateInfo({
        drivingLicenseMainUrl: truckLicenseMainFileList?.value[0]?.fileName || '',
        drivingLicenseSideUrl: truckLicenseSideFileList?.value[0]?.fileName || '',
        ...params
      });
      userVerifiedStore.setCurrentStep(1);
    };

    const vaildateCarNumber = carNumber => {
      const carLicencePattern = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9学警港澳]$/;
      // /^[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新]{1}[A-Z]{1}[A-Z0-9]{5,6}$/;
      const newCarLicensePattern = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z](([0-9]{5}[DABCEFGHJK])|([DABCEFGHJK]([A-HJ-NP-Z0-9])[0-9]{4}))$/;
      if (!carNumber) return '车牌号不能为空！';
      if (!carLicencePattern.test(carNumber) && !newCarLicensePattern.test(carNumber)) return '车牌号格式错误';
      return '';
    };
    const handleToNext = async () => {
      //TODO: 调用一个验证接口 验证该车牌是否已经在平台中添加
      if (!truckLicenseMainFileList?.value[0]?.fileName) {
        Toast.fail('图片不能为空！');
        return;
      }

      // if (userVerifiedStore?.imagePass) {
      //   Toast('图片识别未通过，请重新上传');
      //   return;
      // }
      const carValidErr = vaildateCarNumber(carNumberPro.value);
      if (carValidErr) {
        Toast.fail(carValidErr);
        return;
      } else {
        const isValid = await validateForm();
        if (!isValid) return;
        const istre = ctx.refs.confirm?.show();
        if (!istre) return;
      }
    };

    const handleInput = (value, key) => {
      truckRef.value[key] = value;
    };

    const handlePopConfirm = (e, typeName) => {
      userVerifiedStore.updateInfo({
        [typeName]: e.value,
        [`${typeName}Code`]: e.key
      });
      FormObj[typeName].visible = false;
    };
    const onTruckTypeChange = e => {
      userVerifiedStore.updateInfo({
        truckTypeName: e.value,
        truckTypeCode: e.key
      });
      FormObj.truckTypeName.visible = false;
      onBlur('truckType');
    };
    const onTrailerTypeChange = e => {
      userVerifiedStore.updateInfo({
        trailerType: e.value,
        trailerTypeCode: e.key
      });
      trailerTypeShowRef.value = false;
      validateForm('trailerType');
    };
    const getTrailerNo = e => {
      userVerifiedStore.updateInfo({
        trailerPlateNumber: e
      });
    };

    const getLabel = key => {
      if (key === 'loadWeight') {
        const isTrailer = trailerPlateNumberRequired.value;
        return isTrailer ? '准牵引总质量' : '核定载质量';
      }
      return FormObj[key].label;
    };
    watch(
      () => userVerifiedStore.verifiedInfo?.truckTypeCode,
      truckTypeCode => {
        const isRequired = truckTypeCode && checkTrailerBytype(truckTypeCode);
        FormObj.loadLength.hidden = isRequired;
      }
    );

    const trailerTypeShowRef = ref(false);
    const lengthPopShow = ref(false);
    const trailerTypesOptions = computed(() => {
      return userVerifiedStore.trailerTypeOptions || [];
    });

    const lengthOptions = computed(() => {
      return userVerifiedStore.lengthOptions || [];
    });
    const onLengthConfirm = ({ text }) => {
      userVerifiedStore.updateInfo({
        loadLength: text
      });
      lengthPopShow.value = false;
      validateForm('loadLength');
    };

    const lengthTransfer = val => {
      if ((!val && val !== 0) || isNaN(val)) return null;
      const number = +new BigNumber(val).toFixed(1, BigNumber.ROUND_DOWN);
      return '' + number;
    };
    const onLoadLengthChange = () => {
      const { loadLength } = userVerifiedStore.verifiedInfo;
      const val = lengthTransfer(loadLength);
      userVerifiedStore.updateInfo({
        loadLength: val
      });
    };

    const onBlur = key => {
      if (
        (key === 'loadWeight' || key === 'curbWeight' || key === 'truckType') &&
        truckRef.value.loadWeight &&
        truckRef.value.curbWeight &&
        truckRef.value.truckTypeName
      ) {
        if (truckRef.value.truckTypeName.indexOf('牵引车') > -1) {
          const loadWeightStr = truckRef.value.loadWeight;
          const curbWeightStr = truckRef.value.curbWeight;
          const loadWeight = +loadWeightStr;
          const curbWeight = +curbWeightStr;
          if (!isNaN(loadWeight) && !isNaN(curbWeight)) {
            truckRef.value.totalWeight = new BigNumber(loadWeight).plus(curbWeight).toNumber();
          }
        }
      }
    };

    return {
      next,
      onBlur,
      truckRef,
      popVisible,
      carNumberPro,
      getPlateLicense,
      handleToNext,
      truckLicenseMainFileList,
      truckLicenseSideFileList,
      afterUploadedTruckCard,
      authModeFields,
      isAmendTruckAuthInfo,
      FormObj,
      handleInput,
      handlePopConfirm,
      getTrailerNo,
      getLabel,
      trailerPlateNumberRequired,
      onTruckTypeChange,
      onConfirm,
      trailerTypeShowRef,
      lengthPopShow,
      trailerTypesOptions,
      lengthOptions,
      onLengthConfirm,
      onLoadLengthChange,
      onTrailerTypeChange,
      trailerConfig
    };
  }
});
</script>
<style lang="less">
.truck-card-wrap {
  // padding: 0.04rem 0.08rem;
  .truck-card {
    width: 100%;
    height: 1rem;
  }
}
.form-title {
  position: relative;
  display: inline-block;
  width: auto;
  .important::after {
    position: absolute;
    right: -10px;
    color: #ee0a24;
    font-size: 24px;
    top: -2px;
    text-align: center;
    vertical-align: middle;
    content: '*';
  }
}
</style>
